import type { LigneMetre } from '~/types/models/ligneMetre';
import { usePvHtLigne } from './usePvHtLigne';

export const usePvTtcLignes = (lignes: LigneMetre[]) => {
  const acc = {
    pvHt: 0,
    pvHtParTaux: {} as Record<number, number>,
    montantsTvaParTaux: {} as Record<number, number>,
  };
  const recap = lignes.reduce((acc, l) => {
    acc.pvHt += usePvHtLigne(l);
    if (l.tva) {
      if (!acc.pvHtParTaux[l.tva]) acc.pvHtParTaux[l.tva] = 0;
      acc.pvHtParTaux[l.tva] += usePvHtLigne(l);
      acc.montantsTvaParTaux[l.tva] = useMontantTva(acc.pvHtParTaux[l.tva], useTauxTva(l.tva));
    }
    return acc;
  }, acc);

  return Object.values(recap.montantsTvaParTaux).reduce((acc, m) => acc + m, recap.pvHt);
};
